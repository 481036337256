

























































































import { Component, Vue } from 'vue-property-decorator'
import { apiDeliverymanDelivery } from '@/api/data/data'
import downloadExcel from 'vue-json-excel'
import { funDate } from '@/utils/util'

@Component({
    components: {
        downloadExcel,
    }
})
export default class FinanceProfile extends Vue {
    /** S Data **/
    ProfileData = {}
    timeData: any = []
    // 顶部查询表单
    form = {
        start_time: '',
        end_time: '',
        delivery_status: 0
    }
    loading = false
    exportData: any = [ ] // 导出的表格数据
    tableData: any = [] // 显示的表格数据
    // 头部文字
    header_text:any = ''
    json_fields = {
        "配送员": "name",    //常规字段
        "配送量（盒）":"total_num",
        "单品量":"total_price_str",
    }

    pickerOptions = {
          shortcuts: [{
            text: '最近一周',
            onClick(picker: any) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker: any) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker: any) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
    }
    /** E Data **/


    /** S Methods **/
    
    // 获取数据
    getData() {
        this.getExportData()
    }
    // 获取导出数据
    getExportData() {
        this.tableData = []
        this.exportData = []
        this.loading = true
        console.log("this.form", this.form)
        apiDeliverymanDelivery({
            ...this.form
        }).then(res => {
            this.loading = false
            console.log("res", res)
            this.exportData = [...res]
            this.tableData = [...res]
            console.log("this.tableData", this.tableData)
            let totle_obj:any = {
                name: '合计',
                total_num: 0,
                total_price_str: '',
            }
            this.exportData.forEach((item:any) => {
                totle_obj.total_num+= Number(item.total_num)
                let total_price_arr: any = []
                item.delivery_record.forEach((item2: any) => {
                    total_price_arr.push(item2.name + '*' + item2.num )
                })

                item.total_price_str = total_price_arr.join(",")


                console.log("item", item)
            })

            this.exportData.push({ ...totle_obj})
            console.log("this.exportData", this.exportData)
        })
    }
    // 时间分割
    splitTime() {
        if (this.timeData != null) {
            this.form.start_time = this.timeData[0]
            this.form.end_time = this.timeData[1]
        }
    }
    // 重置
    onReset() {
        let start_time: any = funDate(0);
        let end_time: any = funDate(0)
        this.timeData = [start_time, end_time]
        this.form.start_time = start_time
        this.form.end_time = end_time
        this.form.delivery_status = 0
        this.getExportData()
    }
    /** E Methods **/

    /** S Life Cycle **/
    created() {
        let start_time: any = funDate(0);
        let end_time: any = funDate(0)
        this.timeData = [start_time, end_time]
        this.form.start_time = start_time
        this.form.end_time = end_time
        this.getExportData()
    }
    /** E Life Cycle **/
}
